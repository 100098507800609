.formContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: var(--measure-dimension-gap-lg);

    @media (min-width: 768px) {
        flex-direction: row;
        column-gap: var(--measure-dimension-gap-md);
        row-gap: var(--measure-dimension-gap-lg);
    }
}

.searchContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 768px) {
        align-items: flex-start;
        flex-direction: row;
        width: auto;
    }
}

.dropdownTrigger {
    align-items: center;
    border: var(--measure-dimension-stroke-sm) solid var(--color-base-border-border-subtle);
    border-radius: var(--measure-dimension-radius-md) var(--measure-dimension-radius-md) 0 0;
    background: var(--color-grayscale-color-white);
    display: flex;
    justify-content: space-between;
    padding: var(--measure-dimension-padding-lg) var(--measure-dimension-padding-2xl);
    width: 100%;

    &:focus-visible,
    &:focus,
    &:active {
        border-color: var(--color-base-border-border-subtle);
        outline-color: var(--color-semantics-color-semantic-focus);
        outline-offset: var(--measure-dimension-stroke-sm);
        position: relative;
        z-index: 1;
    }

    &:hover {
        border-color: var(--color-states-hover-border-hover-border);
        outline: none;
    }

    @media (min-width: 768px) {
        border-radius: var(--measure-dimension-radius-md) 0px 0px var(--measure-dimension-radius-md);
        width: 180px;
    }
}

.dropdownMenu {
    background: var(--color-grayscale-color-white);
    border-radius: var(--measure-dimension-radius-md);
    box-shadow: 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11), 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13);
    margin-top: var(--measure-dimension-gap-sm);
    width: 180px;
    z-index: 2;
}

.dropdownItem {
    padding: var(--measure-dimension-padding-md) var(--measure-dimension-padding-2xl);
    cursor: pointer;
    border: var(--measure-dimension-stroke-sm) solid transparent;

    &:hover {
        border-color: var(--color-states-hover-border-hover-border);
    }
}