.fieldSet {
    & .inputContainer {
        &:first-of-type {
            & input {
                border-radius: 0;

                @media (min-width: 768px) {
                    border-radius: 0;
                }
            }
        }

        &:last-of-type input {
            border-radius: 0 0 var(--measure-dimension-radius-md) var(--measure-dimension-radius-md);

            @media (min-width: 768px) {
                border-radius: 0 var(--measure-dimension-radius-md) var(--measure-dimension-radius-md) 0;
            }
        }
    }

    @media (min-width: 768px) {
        display: flex;
    }
}

.inputContainer {
    position: relative;
}

.input {
    --iconSize: 24px;
    border: var(--measure-dimension-stroke-sm) solid var(--color-base-border-border-subtle);
    border-top-width: 0;
    border-radius: 0 0 var(--measure-dimension-radius-md) var(--measure-dimension-radius-md);
    font-family: var(--font-family-secondary);
    padding-block: var(--measure-dimension-padding-lg);
    padding-left: calc(var(--iconSize) + var(--measure-dimension-gap-lg) + var(--measure-dimension-padding-2xl));
    padding-right: calc(var(--iconSize) + var(--measure-dimension-padding-lg));
    width: 100%;

    /* Browser resets */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        appearance: textfield;
        -moz-appearance: textfield;
    }

    &:active,
    &:focus,
    &:focus-visible {
        border-color: var(--color-base-border-border-subtle);
        outline-color: var(--color-semantics-color-semantic-focus);
        outline-offset: 2px;
        position: relative;
        z-index: 1;
        /* Tailwind will be the death of me - this is to override the weird default outline it provides */
        --tw-ring-color: var(--color-semantics-color-semantic-focus);
        --tw-ring-offset-shadow: none;
        --tw-ring-shadow: none;
    }

    &:hover {
        outline: 2px solid var(--color-states-hover-border-hover-border);
        outline-offset: -2px;
    }

    @media (min-width: 768px) {
        border-left-width: 0;
        border-top-width: var(--measure-dimension-stroke-sm);
        border-radius: 0 var(--measure-dimension-radius-md) var(--measure-dimension-radius-md) 0;
        min-width: 186px;

        &.wide {
            min-width: 340px;
        }
    }
}

.icon {
    position: absolute;
    left: 16px;
    top: 8px;
    z-index: 2;
}

.close {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 2;

    &:hover&:after {
        content: none;
    }
}